import React, { useState, useEffect, useMemo } from 'react';
import { useDisplayBannerContext } from 'context/DisplayBannerContext/DisplayBannerContext';
import { FeatureConfigurationProvider } from '../../../../domains/feature-configuration-provider';
import { useXemelgoClient } from '../../../../services/xemelgo-service';
import { ModalForm } from '../../../../components/modal-form';
import DisplayBanner from '../../../../components/display-banner/DisplayBanner';
import Spinner from 'react-bootstrap/Spinner';
import AutoSizeTextArea from '../../../../components/AutoSizeTextArea/AutoSizeTextArea';
import SearchDropdown from '../../../../components/SearchDropdown/SearchDropdown';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import AddDetectorFormV2Style from './AddDetectorFormV2.module.css';
import './style.css';

const FeatureId = 'addResource';
const ERROR_MESSAGE = 'Action was not completed. Please review highlighted items and try again.';
export const AddDetectorFormV2 = ({
  configuration,
  providedArgument,
  show,
  modelId,
  onCancel,
  onSubmit,
}) => {
  const { setShowBanner, setBannerTitle, setBannerHasError } = useDisplayBannerContext();
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [detectorValues, setDetectorValues] = useState([{}]);
  const [showModalBanner, setShowModalBanner] = useState(false);
  const [modalBannerMessage, setModalBannerMessage] = useState('');
  const [classToModeMap, setClassToModeMap] = useState({});
  const [classToActionMap, setClassToActionMap] = useState({});
  const [xemelgoClient] = useState(useXemelgoClient());

  const vendorOptions = useMemo(() => {
    const vendorProperty = properties.find((property) => property.name === 'vendor');
    return vendorProperty?.options || [];
  }, [properties]);

  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!configuration || !providedArgument || !modelId) {
      return cancelCallback;
    }

    const configProvider = FeatureConfigurationProvider.parse(FeatureId, configuration);
    const propertyOrders = configProvider.getValue('propertyOrders', 'array', []);
    const modelConfigProvider = configProvider.getModel(modelId);
    const propertyMap = modelConfigProvider.getPropertyMap();
    const tempClassToModeMap = {};
    const tempClassToActionMap = {};

    const props = propertyOrders
      .filter((propertyId) => {
        return propertyMap[propertyId];
      })
      .map((propertyId) => {
        const property = propertyMap[propertyId];
        const {
          displayName,
          __addable,
          optional,
          optionallyDependsOn,
          optionsProvided,
          name,
          unique,
          defaultValue,
          autoPopulate,
        } = property;

        let { options } = property;
        if (optionsProvided) {
          options = providedArgument[propertyId];
        }

        let formattedOptions = options?.map((option) => {
          return {
            ...option,
            id: option.key,
            label: option.value,
            value: option.value,
          };
        });

        if (propertyId === 'class' && formattedOptions) {
          formattedOptions.forEach((option) => {
            const { properties, id } = option;
            if (properties) {
              const { action = {}, mode = {} } = properties;
              tempClassToActionMap[id] =
                action?.options.map((action) => {
                  return {
                    id: action.key,
                    label: action.value,
                    value: action.value,
                  };
                }) || [];

              // tempClassToModeMap[id] =
              //   mode?.options.map((mode) => {
              //     return {
              //       id: mode.key,
              //       label: mode.value,
              //       value: mode.value
              //     };
              //   }) || [];
            }
          });
        }

        return {
          displayName,
          optional,
          optionallyDependsOn,
          options: formattedOptions,
          editable: __addable,
          name: name || propertyId,
          unique,
          defaultValue,
          autoPopulate,
        };
      });

    if (!cancelled) {
      setProperties(props);
      setClassToActionMap(tempClassToActionMap);
      // setClassToModeMap(tempClassToModeMap);
      setLoading(false);
    }

    return cancelCallback;
  }, [configuration, providedArgument, modelId]);

  const renderLoading = () => {
    return (
      <div className="loading_circle">
        <Spinner animation="border" />
      </div>
    );
  };

  const handleEdit = (propertyName, index, value) => {
    const tempDetectors = [...detectorValues];
    tempDetectors[index][propertyName] = value;
    if (propertyName === 'vid') {
      const vendorOption = vendorOptions.find((option) => {
        return option.prefix && value.toUpperCase().startsWith(option.prefix.toUpperCase());
      });
      if (vendorOption) {
        tempDetectors[index]['vendor'] = vendorOption;
      } else {
        const defaultOption = vendorOptions.find((option) => {
          return !option.prefix;
        });
        tempDetectors[index]['vendor'] = defaultOption;
      }
    }
    setDetectorValues(tempDetectors);
  };

  const handleAddDetector = () => {
    const tempValues = [...detectorValues];
    tempValues.push({});
    setDetectorValues(tempValues);
  };

  const handleRemoveDetector = (index) => {
    const tempValues = [...detectorValues];
    tempValues.splice(index, 1);
    setDetectorValues(tempValues);
  };

  const verifyPayload = async () => {
    const tempValues = [...detectorValues];
    const requiredProperties = properties.filter((property) => !property.optional);
    let errorMessage = ERROR_MESSAGE;
    let canSubmit = true;
    const vidMap = {};
    tempValues.forEach((value, index) => {
      const tempErrObject = {};
      requiredProperties.forEach((property) => {
        if (!value[property.name]) {
          tempErrObject[property.name] = true;
          canSubmit = false;
        }
      });

      if (!vidMap[value.vid]) {
        vidMap[value.vid] = [index];
      } else {
        vidMap[value.vid].push(index);
      }

      if (Object.keys(tempErrObject).length > 0) {
        value.error = tempErrObject;
      } else {
        delete value.error;
      }
    });

    Object.keys(vidMap).forEach((vid) => {
      const currentVid = vidMap[vid];
      if (currentVid.length > 1) {
        currentVid.forEach((index) => {
          if (!tempValues[index].error) {
            tempValues[index].error = {
              vid: true,
            };
          } else {
            tempValues[index].error.vid = true;
          }
        });
        canSubmit = false;
      }
    });

    const detectorClient = xemelgoClient.getDetectorClient();
    const result = await detectorClient.getDetectorsByVids(Object.keys(vidMap));
    const existingVids = [];
    if (result.length) {
      result.forEach((detector) => {
        existingVids.push(detector.vid);
        const currentVid = vidMap[detector.vid];
        currentVid.forEach((index) => {
          if (!tempValues[index].error) {
            tempValues[index].error = {
              vid: true,
            };
          } else {
            tempValues[index].error.vid = true;
          }
        });
      });
      errorMessage = `The following detector(s) already exist: ${existingVids.join(', ')}`;
      canSubmit = false;
    }

    if (!canSubmit) {
      setShowModalBanner(true);
      setModalBannerMessage(errorMessage);
    }

    setDetectorValues(tempValues);
    return canSubmit;
  };

  const handleCreateLocation = async () => {
    const detectorClient = xemelgoClient.getDetectorClient();
    for (const detector of detectorValues) {
      let actions;
      if (detector.action) {
        switch (detector.action.id) {
          case 'endTrackingSession':
            actions = {
              endTrackingSession: {
                detachSensorProfiles: true,
              },
            };
            break;
          case 'sameDetectorEndTrackingSession':
            actions = {
              sameDetectorEndTrackingSession: true,
            };
            break;
          case 'shipping':
            actions = {
              shipment: {
                value: 'In Transit',
              },
            };
            break;
          case 'receiving':
            actions = {
              shipment: {
                value: 'Received',
              },
            };
            break;
          case 'increaseItemUsage':
            actions = {
              increaseItemUsage: true,
              resetRefreshDate: true,
            };
            break;
          case 'entryExit':
            actions = {
              flipHasExitState: true,
            };
            break;
          default:
            break;
        }
      }

      const payload = {
        vid: detector.vid,
        name: detector.vid,
        vendor: detector.vendor?.id,
        class: detector.class?.id,
        mode: detector.mode?.id,
        protocol: detector.vendor?.protocol,
        actions,
      };

      await detectorClient.createDetectorV2(payload, detector.location?.id);
    }
  };

  const renderInput = (property, index) => {
    if (property.options) {
      return (
        <SearchDropdown
          withoutOptionFilter
          options={property.options}
          onItemSelected={(newItem) => {
            handleEdit(property.name, index, newItem);
          }}
          selectedItem={detectorValues[index][property.name]}
          error={detectorValues[index]['error'] && detectorValues[index]['error'][property.name]}
        />
      );
    } else {
      return (
        <AutoSizeTextArea
          onChangeText={(text) => {
            handleEdit(property.name, index, text);
          }}
          value={detectorValues[index][property.name]}
          error={detectorValues[index]['error'] && detectorValues[index]['error'][property.name]}
        />
      );
    }
  };

  const renderDetectors = () => {
    return detectorValues.map((currentValues, index) => {
      const tempProperties = [...properties];
      if (classToActionMap[currentValues?.class?.id]) {
        tempProperties.push({
          name: 'action',
          displayName: 'Detector Actions',
          optional: true,
          options: classToActionMap[currentValues.class.id],
        });
      }

      // if (classToModeMap[currentValues?.class?.id]) {
      //   tempProperties.push({
      //     name: 'mode',
      //     displayName: 'Detector Mode',
      //     optional: true,
      //     options: classToModeMap[currentValues.class.id]
      //   });
      // }

      return (
        <div
          key={`detector${index}`}
          className={`${index > 0 && AddDetectorFormV2Style.top_separator} ${
            AddDetectorFormV2Style.section_container
          }`}
        >
          <div
            className={AddDetectorFormV2Style.add_detector_button}
            onClick={() => {
              if (index === 0) {
                handleAddDetector();
              } else {
                handleRemoveDetector(index);
              }
            }}
          >
            {index === 0 ? '+ Add Detector' : '- Remove Detector'}
          </div>
          <div className={AddDetectorFormV2Style.input_group}>
            {tempProperties.map((property) => {
              return (
                <div key={property.name} className={AddDetectorFormV2Style.input_container}>
                  {`${property.displayName}${property.optional ? '' : '*'}`}
                  {renderInput(property, index)}
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  };

  return (
    <ModalForm
      scrollable
      show={show}
      prefix="detector"
      className="detectorModal"
      title={
        <div
          className={`${AddDetectorFormV2Style.flex_row} ${AddDetectorFormV2Style.title_container}`}
        >
          Add a Detector
          <div className={AddDetectorFormV2Style.close_butoon} onClick={onCancel}>
            <CloseRoundedIcon />
          </div>
        </div>
      }
      body={
        loading ? (
          renderLoading()
        ) : (
          <div>
            {showModalBanner && (
              <DisplayBanner
                onCloseBanner={() => {
                  setShowModalBanner(false);
                }}
                bannerMessage={modalBannerMessage}
                bannerError
              />
            )}
            <div className={AddDetectorFormV2Style.body_container}>
              Detector Information
              {renderDetectors()}
            </div>
          </div>
        )
      }
      footer={
        <div className={AddDetectorFormV2Style.flex_row}>
          <div
            className={`${AddDetectorFormV2Style.button} ${AddDetectorFormV2Style.discard_button}`}
            onClick={onCancel}
          >
            Discard
          </div>
          <div
            className={`${AddDetectorFormV2Style.button} ${AddDetectorFormV2Style.create_button}`}
            onClick={async () => {
              setLoading(true);
              const canSubmit = await verifyPayload();
              if (canSubmit) {
                await handleCreateLocation();
                setBannerHasError(false);
                setBannerTitle('Your detector(s) were created successfully!');
                setShowBanner(true);
                onSubmit();
              }
              setLoading(false);
            }}
          >
            Create
          </div>
        </div>
      }
    />
  );
};
