import React, { useState, useEffect } from 'react';
import { useDisplayBannerContext } from 'context/DisplayBannerContext/DisplayBannerContext';
import { useXemelgoClient } from '../../../../services/xemelgo-service';
import { FeatureConfigurationProvider } from '../../../../domains/feature-configuration-provider';
import { ModalForm } from '../../../../components/modal-form';
import Spinner from 'react-bootstrap/Spinner';
import InputGroup from '../../../../components/my-facility-v2-components/InputGroup';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import EditDetectorFormV2Style from './EditDetectorFormV2.module.css';
import './EditDetectorFormV2.css';

const FeatureId = 'editResource';
export const EditDetectorFormV2 = ({
  configuration,
  modelId,
  show,
  providedArgument,
  resource,
  onCancel,
  onSave,
}) => {
  const { setShowBanner, setBannerTitle, setBannerHasError } = useDisplayBannerContext();
  const [loading, setLoading] = useState(true);
  const [properties, setProperties] = useState([]);
  const [editedProperties, setEditedProperties] = useState({});
  const [xemelgoClient] = useState(useXemelgoClient());

  useEffect(() => {
    let cancelled = false;
    const cancelCallback = () => {
      cancelled = true;
    };

    if (!configuration || !modelId || !resource || !providedArgument) {
      return cancelCallback;
    }

    const configProvider = FeatureConfigurationProvider.parse(FeatureId, configuration);
    console.log(configProvider);
    const propertyOrders = configProvider.getValue('propertyOrders', 'array', []);
    const modelConfigProvider = configProvider.getModel(modelId);
    const propertyMap = modelConfigProvider.getPropertyMap();
    console.log(propertyOrders);
    const props = propertyOrders.map((propertyId) => {
      const property = propertyMap[propertyId];
      const { displayName, __updatable: editable, optionsProvided, name = propertyId } = property;

      let { options } = property;
      if (optionsProvided) {
        options = providedArgument[propertyId];
      }

      if (options && options.length) {
        options = options.map((option) => {
          return {
            id: option.key,
            label: option.value,
          };
        });
      }
      const propertyName = name === 'locationId' ? 'location' : name;

      return {
        options,
        name: propertyName,
        displayName,
        disabled: !editable,
      };
    });

    if (!cancelled) {
      setProperties(props);
      setLoading(false);
    }

    return cancelCallback;
  }, [configuration, modelId, resource, providedArgument]);

  const handleEdit = (id, value) => {
    const tempEditedProperties = { ...editedProperties };
    tempEditedProperties[id] = value;
    setEditedProperties(tempEditedProperties);
  };

  const renderLoading = () => {
    return (
      <div className={EditDetectorFormV2Style.loading_circle}>
        <Spinner animation="border" />
      </div>
    );
  };

  const handleSubmit = async () => {
    setLoading(true);
    const detectorClient = xemelgoClient.getDetectorClient();
    const tempEditedProperties = { ...editedProperties };
    const { location, action, mode } = tempEditedProperties;
    delete tempEditedProperties.location;
    if (location) {
      await detectorClient.changeDetectorLocation(resource.id, location.id);
    }

    if (action || mode) {
      tempEditedProperties.action = tempEditedProperties.action?.id;
      tempEditedProperties.mode = tempEditedProperties.mode?.id;
    }

    let actions;
    if (action) {
      delete tempEditedProperties.action;
      switch (action.id) {
        case 'endTrackingSession':
          actions = {
            endTrackingSession: {
              detachSensorProfiles: true,
            },
          };
          break;
        case 'sameDetectorEndTrackingSession':
          actions = {
            sameDetectorEndTrackingSession: true,
          };
          break;
        case 'shipping':
          actions = {
            shipment: {
              value: 'In Transit',
            },
          };
          break;
        case 'receiving':
          actions = {
            shipment: {
              value: 'Received',
            },
          };
          break;
        case 'increaseItemUsage':
          actions = {
            increaseItemUsage: true,
            resetRefreshDate: true,
          };
          break;
        case 'entryExit':
          actions = {
            flipHasExitState: true,
          };
          break;
        default:
          break;
      }
      tempEditedProperties.actions = actions;
    }

    if (Object.keys(tempEditedProperties).length > 0) {
      await detectorClient.updateDetectorV2(resource.id, tempEditedProperties);
    }
    setBannerHasError(false);
    setBannerTitle(`Successfully updated ${resource.name}`);
    setShowBanner(true);
    setLoading(false);
    onSave();
  };

  return (
    <ModalForm
      scrollable
      show={show}
      prefix="location"
      className={EditDetectorFormV2Style.location_modal}
      title={
        <div
          className={`${EditDetectorFormV2Style.flex_row} ${EditDetectorFormV2Style.title_container}`}
        >
          {`Edit ${resource.name}`}
          <div onClick={onCancel} className={EditDetectorFormV2Style.close_button}>
            <CloseRoundedIcon />
          </div>
        </div>
      }
      body={
        loading ? (
          renderLoading()
        ) : (
          <div>
            <InputGroup
              title="Location Information"
              properties={properties}
              onChange={handleEdit}
              values={editedProperties}
              placeholders={resource}
            />
          </div>
        )
      }
      footer={
        <div className={EditDetectorFormV2Style.flex_row}>
          <div
            className={`${EditDetectorFormV2Style.button} ${EditDetectorFormV2Style.discard_button}`}
            onClick={onCancel}
          >
            Discard
          </div>
          <div
            className={`${EditDetectorFormV2Style.button} ${EditDetectorFormV2Style.create_button}`}
            onClick={handleSubmit}
          >
            Save
          </div>
        </div>
      }
    />
  );
};
